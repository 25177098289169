@if (augmentedPurchaseOrder) {
   <div class="listItem lim-ui-responsive-table-row">
      <div
         class="column-container col-md-1 lim-ui-responsive-table-cell dropdown-name-and-icons-container"
      >
         <div class="name-and-icons-container">
            <lim-ui-icon icon="filePowerpoint" />
            <div
               (click)="popPurchaseOrder(augmentedPurchaseOrder)"
               class="table-column-badge-and-name-column"
            >
               <a class="table-link">{{ augmentedPurchaseOrder.poNumberForDisplay }} </a>
               @if (searchHints?.get(augmentedPurchaseOrder.poID); as searchHint) {
                  <div
                     class="table-column-badge lim-ui-show-on-desktop"
                     [limbleHtml]="searchHint"
                  ></div>
               }
            </div>
         </div>

         <!-- Menu button -->
         <lim-ui-dropdown class="lim-ui-hide-on-desktop button-menu">
            <lim-ui-minimal-icon-button
               id="mobile-widget-buttons"
               button
               icon="ellipsisVertical"
            />
            <div menu aria-labelledby="mobile-widget-buttons">
               <lim-ui-dropdown-item (click)="setReminder(); $event.stopPropagation()">
                  <lim-ui-icon icon="cloneRegular" />
                  {{ lang().SetAReminder }}
               </lim-ui-dropdown-item>
               <lim-ui-dropdown-divider />
               <lim-ui-dropdown-item (click)="deletePO(); $event.stopPropagation()">
                  <lim-ui-icon icon="trashCanRegular" iconColor="danger" />
                  {{ lang().DeletePO }}
               </lim-ui-dropdown-item>
            </div>
         </lim-ui-dropdown>
      </div>
      <div class="column-container col-md-1 lim-ui-responsive-table-cell">
         <div class="lim-ui-mobile-table-column-label">{{ lang().Total }}:</div>
         {{ augmentedPurchaseOrder.total | betterCurrency }}
      </div>
      <div class="column-container col-md-2 lim-ui-responsive-table-cell">
         @if (augmentedPurchaseOrder.poItemIDs.length) {
            <po-item-list-short
               [purchaseOrderItemIDs]="augmentedPurchaseOrder.poItemIDs"
               [dataLogOptions]="dataLogOptions"
            />
         }
      </div>

      <div class="column-container col-md-2 lim-ui-responsive-table-cell vendor-column">
         <div class="lim-ui-mobile-table-column-label">{{ lang().Vendor }}:</div>
         <a (click)="popVendor(augmentedPurchaseOrder.vendorID)" class="table-link">
            {{ augmentedPurchaseOrder.vendorNameStr }}
         </a>
         @if (augmentedPurchaseOrder.vendorID == 0) {
            <lim-ui-minimal-icon-button
               icon="plus"
               class="vendor-hover-buttons"
               iconColor="success"
               limUiTooltip="{{ lang().ChangeWhichVendorThisPOBelongsTo }}"
               (click)="pickVendor(); $event.stopPropagation()"
            />
         }
         @if (
            augmentedPurchaseOrder.vendorID !== null &&
            augmentedPurchaseOrder.vendorID > 0
         ) {
            <lim-ui-minimal-icon-button
               class="vendor-hover-buttons"
               icon="pencil"
               limUiTooltip="{{ lang().ChangeWhichVendorThisPOBelongsTo }}"
               (click)="pickVendor(); $event.stopPropagation()"
            />
         }
      </div>

      <div class="column-container col-md-1 lim-ui-responsive-table-cell">
         <div class="lim-ui-mobile-table-column-label">{{ lang().PODate }}:</div>
         {{ augmentedPurchaseOrder.dateDisplay | betterDate: "date" }}
      </div>

      <div class="column-container col-md-1 lim-ui-responsive-table-cell">
         @if (augmentedPurchaseOrder.poID) {
            <po-delivery-date [poID]="augmentedPurchaseOrder.poID" />
         }
      </div>

      <div class="column-container col-md-1 lim-ui-responsive-table-cell">
         <div class="lim-ui-mobile-table-column-label">{{ lang().POStatus }}:</div>
         <span (click)="popPurchaseOrder(augmentedPurchaseOrder)">
            <span [limbleHtml]="currentState?.name"></span>
            @if (
               augmentedPurchaseOrder.state == 0 &&
               augmentedPurchaseOrder.requestedByUserID &&
               augmentedPurchaseOrder.requestedByUserID > 0
            ) {
               <span
                  limUiTooltip="{{ lang().RequestedBy }} {{
                     augmentedPurchaseOrder.requestedUser
                  }}"
               >
                  - <i>{{ lang().Requested }}</i>
               </span>
            }
         </span>
      </div>
      <div
         (click)="popPurchaseOrder(augmentedPurchaseOrder)"
         class="column-container col-md-2 lim-ui-responsive-table-cell"
      >
         <div>
            <div class="lim-ui-mobile-table-column-label">{{ lang().AssignedTo }}:</div>
            <a
               (click)="changePOAssignment(); $event.stopPropagation()"
               class="table-link"
               [limbleHtml]="augmentedPurchaseOrder.displayName"
            ></a>
         </div>
      </div>

      <div class="col-md-1 hover-buttons-and-mobile-badge-container">
         <div class="lim-ui-show-on-desktop hover-button-container">
            <lim-ui-minimal-icon-button
               class="hover-buttons"
               icon="bellRegular"
               limUiTooltip="{{ lang().SetAReminder }}"
               (click)="setReminder(); $event.stopPropagation()"
            />
            <lim-ui-minimal-icon-button
               class="hover-buttons"
               icon="trashCanRegular"
               iconColor="danger"
               (click)="deletePO(); $event.stopPropagation()"
            />
         </div>
         @if (searchHints?.get(augmentedPurchaseOrder.poID); as searchHint) {
            <div
               class="table-column-badge lim-ui-show-on-desktop"
               [limbleHtml]="searchHint"
            ></div>
         }
      </div>
   </div>
}
